@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.provenance {
  text-align: center;
  margin: 0 auto;
  padding-top: 8rem;

  > h2 {
    font-size: 4rem;
    font-weight: 500;
  }

  > p {
    display: inline-flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1024px) {
  .provenance {
    > h2 {
      font-size: 3rem;
    }
    > p {
      font-size: 1.2rem;
    }
  }
}
@media screen and (max-width: 720px) {
  .provenance {
    padding-top: 2rem;

    > h2 {
      font-size: 1.7rem;
    }
    > p {
      font-size: 1rem;
    }
  }
}
