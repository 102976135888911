@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.hero {
  flex: 1;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  max-width: 100%;
  max-height: 100%;

  padding: 6rem 8rem 0 8rem;

  > .left {
    flex: 1;

    display: inline-flex;
    flex-direction: column;
    gap: 3rem;

    > .title {
      position: relative;

      > h1 {
        font-size: clamp(0px, 5rem, 15vw);
        font-weight: 600;
        opacity: 0;
        animation: fade-in 0.5s ease-in 0.5s forwards;
      }

      > h2 {
        font-size: 1.3rem;
        font-weight: 500;
        opacity: 0;
        animation: fade-in 0.5s ease-in 1s forwards;
      }
    }

    > .minting {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.6rem;

      font-size: 1.2rem;
      font-weight: 500;

      > .action {
        background-color: white;
        display: flex;
        align-items: stretch;
        width: 11rem;
        height: 2.2rem;
        border: 1px solid #ffffff;
        border-radius: 4px;
        position: relative;

        > .count {
          position: relative;
          color: black;
          font-size: 1.2rem;
          font-weight: 500;
          width: 45%;
          text-align: center;
          padding-right: 5%;
          display: grid;
          place-items: center;

          > p {
            color: inherit;
          }

          > .arrow {
            position: absolute;
            top: 50%;
            right: 0%;
            cursor: pointer;
            width: 0.75rem;
            height: auto;

            &#up {
              transform: rotateZ(180deg) translateY(120%);
            }
            &#down {
              transform: translateY(20%);
            }
          }
        }

        > .price {
          color: white;
          position: absolute;
          left: calc(100% + 1rem);
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;

          font-size: 1.2rem;

          width: fit-content;
        }

        > button {
          width: 55%;
          height: 100%;
          background-color: #232323;
          color: white;
          clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
          border: none;
          padding-left: 1rem;
          text-align: center;
          cursor: pointer;
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
          font-size: 1.2rem;

          &:disabled {
            cursor: not-allowed;
          }
        }
      }

      > .progress {
        display: inline-flex;
        align-items: center;
        gap: 0.4rem;

        > .bar {
          position: relative;
          display: block;
          width: 13rem;
          height: 0.8rem;
          background-color: #232323;
          border-radius: 0.4rem;

          > .filled {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: white;
            border-radius: 0.4rem;
          }
        }
      }
      > h3 {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        opacity: 0;
        animation: fade-in 0.5s ease-in 1.5s forwards;
      }
    }
  }

  > .right {
    height: 100%;
    flex: 1;
    max-width: 35rem;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > img {
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin-right: -20%;
      opacity: 0;
      animation: slide-in-from-right 1.1s cubic-bezier(0.175, 0.9, 0.32, 1.1)
        0.4s forwards;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        265.76deg,
        #000000 25.43%,
        #000000 25.43%,
        rgba(0, 0, 0, 0) 61.88%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../assets/hero-background.jpg');
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-position: center;
    background-size: cover;

    animation: fade-in 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@media screen and (max-width: 1400px) {
  .hero {
    padding: 6rem 6rem 0 6rem;

    > .left {
      flex: 1;

      gap: 2.5rem;
      max-width: 30rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hero {
    flex-direction: column;
    padding: 6rem 1.25rem 0 1.25rem;

    > .right {
      > img {
        margin: 0;
      }
    }
  }
}
