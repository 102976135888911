@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0009;
    backdrop-filter: blur(2px);
    animation: black-out 300ms ease-out forwards;
  }
  .content {
    position: relative;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fade-in 200ms ease-out forwards;
    max-width: 100%;
  }

  .dismissBackdrop {
    animation: black-out-reverse 200ms ease-out forwards;
  }
  .dismiss {
    animation: fade-out 150ms ease-out forwards;
  }
}

// ANIMATIONS
@keyframes black-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes black-out-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(0, 5rem) scale(0.9);
  }
  50% {
    opacity: 0.5;
    transform: translate(0, -1rem) scale(1.01);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  50% {
    opacity: 0.5;
    transform: translate(0, -1rem) scale(1.01);
  }
  100% {
    opacity: 0;
    transform: translate(0, 5rem) scale(0.9);
  }
}
