@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.header {
  display: inline-flex;
  justify-content: space-between;

  height: $page-margin;
  padding: 0.8rem calc($page-margin + 2rem);

  border-bottom: 0.5px solid #e6e6e6;

  &::after,
  &::before {
    content: '';
    position: absolute;
    $size: $page-margin;
    width: $size;
    height: $size;
    top: 0;
  }

  &::before {
    left: 0;
    border-right: 0.5px solid #e6e6e6;
  }

  &::after {
    right: 0;
    border-left: 0.5px solid #e6e6e6;
  }

  > .left,
  > .right {
    display: inline-flex;
    align-items: center;
  }

  > .left {
    width: min-content;
    height: 100%;

    > a {
      height: 100%;

      > img {
        width: auto;
        height: 100%;
        transform: scale(1.8);
        margin-left: 40%;
        transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          animation: logo-spin 2s ease 1;
        }
      }
    }

    > p {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  > .right {
    button {
      position: relative;

      padding: 0.4rem 1.5rem;
      background: #1a1a1a;
      border: 0.5px solid #e6e6e6;
      box-shadow: 0px 0px 2px #e6e6e6, inset 0px 0px 2px #e6e6e6;
      border-radius: 8px 0px;
      color: inherit;
      min-width: 8rem;

      cursor: pointer;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        background-color: white;
      }

      &::before {
        left: 0;
        bottom: 0;
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      }

      &::after {
        right: 0;
        top: 0;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
      }
    }

    > img {
      margin-left: 0.5rem;
      cursor: pointer;
      height: 70%;
      width: auto;
    }
  }
}

@media screen and (max-width: 960px) {
  .header {
    padding: 0.8rem 0.6rem;
    position: relative;

    &::after,
    &::before {
      display: none;
    }
  }
}
