@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.splashWrapper {
  background: url('./bg-splash.webp'), url('./bg-splash.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-width: 310px;
  max-width: 44rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: auto;
  z-index: 1010;
  color: white;
  text-align: center;
  border-radius: 0.7rem;
  border: 1px solid rgba(171, 0, 255, 1);
  padding: 2rem 2rem 3rem 2rem;
  box-shadow: 0px 0px 5px 1px rgba(171, 0, 255, 1);

  > .splashImg {
    width: 75%;
    display: block;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  > .links {
    margin-top: -1rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 1rem;

    a {
      font-size: 1.2rem;
      text-decoration: underline;
      color: white;
      transition: color 200ms ease;
      margin: 0 1rem;
      cursor: pointer;

      &:hover {
        color: #ab00ff;
      }
    }
  }

  > .syncBtn {
    cursor: pointer;
    margin-top: 2rem;
    align-self: center;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    border-radius: 9999px;
    border: none;
    background-color: #ab00ff;
    color: white;
    transition: filter 150ms ease;

    &:disabled {
      opacity: 0.8;
      cursor: wait;
    }

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.8);
    }
  }
}
