@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.sidebar {
  width: $page-margin;
  height: 100%;
  position: absolute;
  top: 0;

  &#left {
    border-right: 0.5px solid #e6e6e6;
    left: 0;
  }
  &#right {
    border-left: 0.5px solid #e6e6e6;
    right: 0;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
}
