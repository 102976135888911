@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.footer {
  width: 100%;
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  min-height: 45vw;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  &::after {
    content: '';
    z-index: -2;
    background-image: url('../../assets/footer-background.png');
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  > .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.97) 25.34%,
      rgba(0, 0, 0, 0.16) 100%
    );
  }

  > p {
    font-family: 'Press Start 2p';
    font-size: 9vw;
    line-height: 100%;
    letter-spacing: 0.02rem;
    text-align: center;
    color: rgba(230, 230, 230, 0.16);

    align-self: center;
    pointer-events: none;
  }

  > .footer-content {
    margin-top: calc(-3.5vw + 2rem);
    display: inline-flex;
    gap: clamp(2rem, 3rem, 3vw);
    justify-content: center;
    align-items: stretch;

    > a {
      display: grid;
      place-items: center;

      > img {
        width: clamp(0rem, 2.8rem, 7vw);
        fill: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          fill: rgba(255, 255, 255, 1);
          transform: scale(1.1);
        }
      }
    }
  }
}
