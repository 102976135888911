@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.artist {
  margin-top: 8rem;
  padding: 0 10%;

  > h1 {
    color: white;
    font-weight: 500;
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;

    > img {
      width: clamp(0px, 100%, 17rem);
    }

    > div {
      flex: 1;

      > h2 {
        font-size: 1.7rem;
      }

      > p {
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }

      > a {
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 2rem;
        color: #f268d4;

        > img {
          margin-left: 0.4rem;
          transition: margin 0.2s ease-in-out;
        }

        &:hover {
          > img {
            margin-left: 0.8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .artist {
    margin-top: 2rem;

    > h1 {
      font-size: 3rem;
    }

    > div {
      flex-direction: column;
      gap: 3rem;

      > div {
        > p {
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .artist {
    > h1 {
      font-size: 1.7rem;
    }

    > div {
      > div {
        > p {
          font-size: 1.1rem;
        }
      }
    }
  }
}
