@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.node-selector {
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  background-color: black;
  box-shadow: 0px 0px 4px -1px white, 0px 0px 5px 0px white;
  max-width: 100%;

  > h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  > ul {
    list-style: none;

    > li {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 0.5rem;
      padding: 0.2rem 0.4rem;
      border-radius: 0.5rem;
      margin-left: -0.4rem;

      &:hover,
      &.selected {
        background-color: white;

        > label {
          color: black;
          font-size: 1rem;
        }

        > input[type='text'] {
          color: black;
        }
      }

      &#custom {
        > input[type='text'] {
          border: none;
          background: transparent;
          outline: none;
          font-size: 1rem;
        }
      }
    }
  }

  > button {
    background-color: black;
    border: 1px solid white;
    padding: 0.2rem 0.7rem;

    text-transform: uppercase;
    border-radius: 0.5rem;

    cursor: pointer;
  }
}
