@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 2rem;

  .countdown {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 0 2rem;

    .heading {
      display: flex;
      flex-direction: column;

      text-shadow: -1px -1px 0 #5fbcff, 1px -1px 0 #5fbcff, -1px 1px 0 #5fbcff,
        1px 1px 0 #5fbcff;
      color: black;
      font-weight: 600;
      font-size: 2.9rem;
    }

    .time-container {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 1rem;

      .time {
        display: flex;
        align-items: flex-start;

        .number {
          font-weight: 500;
          font-size: 2.9rem;
          line-height: 100%;
        }

        .type {
          font-weight: 300;
        }
      }
    }
  }

  > .main {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 0 10%;
    margin-top: 2rem;

    > .content {
      display: inline-flex;
      flex-direction: column;
      gap: 1.2rem;
      font-size: 1.1rem;
      font-weight: 400;
      flex: 1;

      > ul {
        list-style-position: inside;
        display: grid;
        grid-template-columns: repeat(2, auto);
      }
    }

    > .image-container {
      flex: 1;
      $size: 25rem;
      max-width: $size;
      max-height: $size;
      width: 100%;
      height: 100%;

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .details {
    .main {
      gap: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .details {
    .countdown {
      gap: 1rem;

      .heading {
        font-size: 2.1rem;
      }

      .time-container {
        gap: 0.5rem;

        .time {
          .number {
            font-size: 2.1rem;
          }

          .type {
            font-size: 0.8rem;
          }
        }
      }
    }

    > .main {
      flex-direction: column;
      gap: 2rem;
      padding: 0 10%;
      margin-top: 4rem;
    }
  }
}

@media screen and (max-width: 520px) {
  .details {
    .countdown {
      gap: 2rem;

      .heading {
        font-size: 2rem;
      }

      .time-container {
        gap: 0.5rem 1.5rem;

        .time {
          .number {
            font-size: 2rem;
          }

          .type {
            font-size: 0.7rem;
          }
        }
      }
    }
    > .main {
      flex-direction: column;
      gap: 4rem;
      padding: 0 1rem;
      margin-top: 4rem;
    }
  }
}

@media screen and (max-width: 430px) {
  .details {
    .countdown {
      gap: 1rem;

      .heading {
        font-size: clamp(0px, 2rem, 7vw);
      }

      .time-container {
        gap: 1rem;

        .time {
          .number {
            font-size: 2rem;
          }

          .type {
            font-size: 0.7rem;
          }
        }
      }
    }
    > .main {
      flex-direction: column;
      gap: 4rem;
      padding: 0 1rem;
      margin-top: 4rem;
    }
  }
}
