@import 'styles/_variables.scss';
@import 'styles/keyframes';

@import 'styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Press+Start+2P&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif, 'Arimo';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

html {
  background-color: black;
}

#root {
  max-width: 100%;

  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  > main {
    flex: 1;
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    padding: 0 $page-margin;

    @media screen and (max-width: 960px) {
      padding: 0;
    }
  }
}

a,
a:visited,
a:active {
  color: inherit;
}
