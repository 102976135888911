@import '../../styles/_variables.scss';
@import '../../styles/keyframes';

.class-distribution {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  > h1 {
    color: #5fbcff;
    font-weight: 500;
    font-size: 4rem;
    text-align: center;
  }
  > .description {
    font-size: 1.2rem;
    max-width: 50rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  > .classes-container {
    display: inline-flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;

    > .class {
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        display: inline-flex;
        flex-direction: column;
        text-align: center;

        font-weight: 500;
        font-size: 1.4rem;

        > span {
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .class-distribution {
    margin-top: 2rem;
    padding: 0 4rem;

    > h1 {
      font-size: 3rem;
    }
    > .description {
      font-size: 1.1rem;
    }

    > .classes-container {
      gap: 0rem;

      > .class {
        > p {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .class-distribution {
    padding: 0 1rem;

    > h1 {
      font-size: 1.7rem;
    }
    > .description {
      font-size: 1.1rem;
    }

    > .classes-container {
      flex-direction: column;

      > .class {
        flex-direction: row;

        > p {
          font-size: 1rem;
        }
      }
    }
  }
}
