@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-from-right {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes logo-spin {
  0% {
    transform: rotateZ(0deg) scale(1.8);
  }
  100% {
    transform: rotateZ(360deg) scale(1.8);
  }
}
